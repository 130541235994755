<template>
  <el-dialog
    v-drag
    title="生成账单"
    :visible.sync="dialogVisible"
    width="800px"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div class="content">
      <el-form ref="form" :model="form" :rules="rules" label-position="top">
        <el-row>
          <el-col :span="12">
            <el-form-item label="客户" prop="companyId">
              <el-select
                v-model="form.companyId"
                style="width: 320px"
                filterable
                placeholder="请选择客户"
                @change="onChange"
              >
                <el-option
                  v-for="item in companyOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="费用日期" prop="endDate">
              <div class="datePicker">
                <el-date-picker
                  v-model="form.startDate"
                  type="date"
                  style="width: 150px;"
                  disabled
                  value-format="yyyy-MM-dd"
                  placeholder="开始日期">
                </el-date-picker>
                <span style="margin: 0 6px;">-</span>
                <el-date-picker
                  v-model="form.endDate"
                  type="date"
                  style="width: 150px;"
                  placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="账单号" prop="invoiceNumber">
              <el-input
                v-model="form.invoiceNumber"
                style="width: 320px"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取消</div>
      <div v-loading="loading" class="ok flex_c_c" @click="ok('form')">生成</div>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { generateInvoices, getCustomerInvoiceStartDate } from '@/api/fee'
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {
        companyId: '',
        invoiceNumber: '',
        startDate: '',
        endDate: ''
      },
      rules: {
        companyId: [
          { required: true, message: '请选择客户', trigger: 'change' }
        ],
        endDate: [
          { required: true, message: '请输入费用日期', trigger: 'blur' }
        ]
      },
      pickerOptions: {
        disabledDate: (time) => {
          const startDate = new Date(this.form.startDate).getTime() + (24 * 60 * 60 * 1000)
          const today = new Date().getTime() - (24 * 60 * 60 * 1000)
          return time.getTime() < startDate || time.getTime() > today
        }
      },
      loading: false
    }
  },
  computed: {
    ...mapGetters(['companyDrapdown']),
    companyOptions() {
      return this.companyDrapdown
    }
  },
  methods: {
    cancel() {
      this.form.startDate = ''
      this.$refs['form'].resetFields()
      this.$emit('cancel')
    },
    ok(formName) {
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
          if (!this.form.endDate) {
            this.$message.error('请选择结束日期')
            return
          }
          this.loading = true
          try {
            const params = {
              companyId: this.form.companyId,
              startDate: this.form.startDate,
              endDate: this.form.endDate
            }
            const result = await generateInvoices(params)
            if (result.success) {
              this.form.invoiceNumber = result.data
              this.$emit('ok')
              this.form.startDate = ''
              this.$refs[formName].resetFields()
              this.loading = false
            } else {
              this.$message.error(result.data)
              this.loading = false
            }
          } catch (error) {
            console.error(error)
          }
        } else {
          return false
        }
      })
    },
    async onChange() {
      try {
        const res = await getCustomerInvoiceStartDate({ companyId: this.form.companyId })
        if (res.success) {
          this.form.startDate = res.data
          this.form.endDate = ''
        } else {
          this.form.startDate = ''
          this.form.endDate = ''
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: calc(100% - 80px);
  height: auto;
  margin: 0 auto;
  text-align: left;
  .el-form {
    .el-col:nth-child(2n) {
      display: flex;
      justify-content: flex-end;
    }
  }
  .datePicker {
    width: 320px;
    height: 40px;
    box-sizing: border-box;
    border-radius: 4px;
  }
}
.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #eeeeee;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 16px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>
