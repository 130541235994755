var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        title: "生成账单",
        visible: _vm.dialogVisible,
        width: "800px",
        "show-close": true,
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.cancel,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-position": "top",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户", prop: "companyId" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "320px" },
                              attrs: {
                                filterable: "",
                                placeholder: "请选择客户",
                              },
                              on: { change: _vm.onChange },
                              model: {
                                value: _vm.form.companyId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "companyId", $$v)
                                },
                                expression: "form.companyId",
                              },
                            },
                            _vm._l(_vm.companyOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "费用日期", prop: "endDate" } },
                        [
                          _c(
                            "div",
                            { staticClass: "datePicker" },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "150px" },
                                attrs: {
                                  type: "date",
                                  disabled: "",
                                  "value-format": "yyyy-MM-dd",
                                  placeholder: "开始日期",
                                },
                                model: {
                                  value: _vm.form.startDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "startDate", $$v)
                                  },
                                  expression: "form.startDate",
                                },
                              }),
                              _c("span", { staticStyle: { margin: "0 6px" } }, [
                                _vm._v("-"),
                              ]),
                              _c("el-date-picker", {
                                staticStyle: { width: "150px" },
                                attrs: {
                                  type: "date",
                                  placeholder: "结束日期",
                                  "value-format": "yyyy-MM-dd",
                                  "picker-options": _vm.pickerOptions,
                                },
                                model: {
                                  value: _vm.form.endDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "endDate", $$v)
                                  },
                                  expression: "form.endDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "账单号", prop: "invoiceNumber" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "320px" },
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.invoiceNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "invoiceNumber", $$v)
                              },
                              expression: "form.invoiceNumber",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { staticClass: "footers", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "div",
            { staticClass: "cancel flex_c_c", on: { click: _vm.cancel } },
            [_vm._v("取消")]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "ok flex_c_c",
              on: {
                click: function ($event) {
                  return _vm.ok("form")
                },
              },
            },
            [_vm._v("生成")]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }