var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "view-contanier" },
    [
      _c("div", { staticClass: "header" }, [
        _c(
          "div",
          { staticClass: "opt" },
          [
            _vm.filterList.invoiceDate && _vm.isActive
              ? _c("div", { staticClass: "checkDate flex_c_c" }, [
                  _vm._v(
                    " 查询时间：" +
                      _vm._s(
                        _vm.filterList.invoiceDate[0] +
                          "~" +
                          _vm.filterList.invoiceDate[1]
                      ) +
                      " "
                  ),
                ])
              : _vm._e(),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "permissson",
                    rawName: "v-permissson",
                    value: "generate",
                    expression: "'generate'",
                  },
                ],
                staticClass: "generate flex_c_c",
                on: {
                  click: function ($event) {
                    _vm.dialogVisible1 = true
                  },
                },
              },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/const/generate.png"),
                    alt: "生成账单",
                  },
                }),
                _vm._v(" 生成账单 "),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "permissson",
                    rawName: "v-permissson",
                    value: "derive",
                    expression: "'derive'",
                  },
                ],
                staticClass: "derive flex_c_c",
                on: { click: _vm.derive },
              },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/const/derive.png"),
                    alt: "导出",
                  },
                }),
                _vm._v(" 导出 "),
              ]
            ),
            _c(
              "BaseFilter",
              {
                attrs: { isActive: _vm.isActive, width: 466 },
                on: { sendFilter: _vm.sendFilter, reset: _vm.reset },
              },
              [
                _c(
                  "div",
                  { staticClass: "filter" },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "form",
                        attrs: { model: _vm.filterList, "label-width": "70px" },
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "账单日期" } },
                          [
                            _c("el-date-picker", {
                              staticStyle: { width: "320px" },
                              attrs: {
                                type: "daterange",
                                "range-separator": "至",
                                align: "center",
                                "start-placeholder": "开始日期",
                                "end-placeholder": "结束日期",
                                "value-format": "yyyy-MM-dd",
                                "unlink-panels": "",
                                "picker-options": _vm.pickerOptions,
                              },
                              model: {
                                value: _vm.filterList.invoiceDate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filterList, "invoiceDate", $$v)
                                },
                                expression: "filterList.invoiceDate",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "账单状态" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "320px" },
                                attrs: {
                                  filterable: "",
                                  placeholder: "请选择",
                                },
                                model: {
                                  value: _vm.filterList.status,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filterList, "status", $$v)
                                  },
                                  expression: "filterList.status",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "未审核", value: "0" },
                                }),
                                _c("el-option", {
                                  attrs: { label: "已审核", value: "1" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "业务员" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "320px" },
                                attrs: {
                                  filterable: "",
                                  placeholder: "请选择",
                                  multiple: "",
                                },
                                model: {
                                  value: _vm.filterList.saleIds,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filterList, "saleIds", $$v)
                                  },
                                  expression: "filterList.saleIds",
                                },
                              },
                              _vm._l(_vm.userOptions, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "客户" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "320px" },
                                attrs: {
                                  filterable: "",
                                  placeholder: "请选择",
                                },
                                model: {
                                  value: _vm.filterList.companyId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filterList, "companyId", $$v)
                                  },
                                  expression: "filterList.companyId",
                                },
                              },
                              _vm._l(_vm.companyOptions, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "账单号" } },
                          [
                            _c("base-muti-input", {
                              ref: "baseMutiInput",
                              staticStyle: { width: "320px" },
                              model: {
                                value: _vm.filterList.numbers,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filterList, "numbers", $$v)
                                },
                                expression: "filterList.numbers",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "data" },
        [
          _vm.height !== 0
            ? _c("BaseTable", {
                attrs: {
                  height: _vm.height,
                  tableData: _vm.tableData,
                  pageIndex: _vm.pager.pageIndex,
                  pageSize: _vm.pager.pageSize,
                  loading: _vm.loading,
                },
                on: {
                  handlePageChange: _vm.handlePageChange,
                  handleSelectionChange: _vm.handleSelectionChange,
                  sortChange: _vm.sortChange,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "statusName",
                      fn: function (row) {
                        return [
                          row.scope.status === 0
                            ? _c(
                                "div",
                                { staticClass: "flex_c_c status red" },
                                [
                                  _vm._v(
                                    " " + _vm._s(row.scope.statusName) + " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          row.scope.status === 1
                            ? _c(
                                "div",
                                { staticClass: "flex_c_c status green" },
                                [
                                  _vm._v(
                                    " " + _vm._s(row.scope.statusName) + " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: "opt",
                      fn: function (row) {
                        return [
                          _c("div", { staticClass: "table-opt" }, [
                            !row.scope.status
                              ? _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "permissson",
                                        rawName: "v-permissson",
                                        value: "audit",
                                        expression: "'audit'",
                                      },
                                    ],
                                    on: {
                                      click: function ($event) {
                                        return _vm.audit(row.scope)
                                      },
                                    },
                                  },
                                  [_vm._v("审核")]
                                )
                              : _vm._e(),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "permissson",
                                    rawName: "v-permissson",
                                    value: "detail",
                                    expression: "'detail'",
                                  },
                                ],
                                on: {
                                  click: function ($event) {
                                    return _vm.detail("FYCX", row.scope)
                                  },
                                },
                              },
                              [_vm._v("详情")]
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "custom-total",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "custom-total" }, [
                            _c("div", { staticStyle: { display: "flex" } }, [
                              _c("div", { staticClass: "select" }, [
                                _vm._v(
                                  "已选 " +
                                    _vm._s(_vm.selectionItem.length) +
                                    " 项"
                                ),
                              ]),
                              _c("div", { staticClass: "total" }, [
                                _vm._v(" 账单金额："),
                                _c("span", [
                                  _vm._v(_vm._s(_vm.totalAmount) + " USD"),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  698255940
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _c("GenerateBillDialog", {
        attrs: { dialogVisible: _vm.dialogVisible1 },
        on: {
          cancel: function ($event) {
            _vm.dialogVisible1 = false
          },
          ok: _vm.ok,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }