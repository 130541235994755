<template>
  <div class="view-contanier">
    <div class="header">
      <div class="opt">
        <div v-if="filterList.invoiceDate && isActive" class="checkDate flex_c_c">
          查询时间：{{ filterList.invoiceDate[0] + "~" + filterList.invoiceDate[1] }}
        </div>
        <div
          v-permissson="'generate'"
          class="generate flex_c_c"
          @click="dialogVisible1 = true"
        >
          <img src="@/assets/images/const/generate.png" alt="生成账单" />
          生成账单
        </div>
        <div v-permissson="'derive'" class="derive flex_c_c" @click="derive">
          <img src="@/assets/images/const/derive.png" alt="导出" />
          导出
        </div>
        <BaseFilter
          :isActive="isActive"
          :width="466"
          @sendFilter="sendFilter"
          @reset="reset"
        >
          <div class="filter">
            <el-form ref="form" :model="filterList" label-width="70px">
              <el-form-item label="账单日期">
                <el-date-picker
                  v-model="filterList.invoiceDate"
                  style="width: 320px"
                  type="daterange"
                  range-separator="至"
                  align="center"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                  unlink-panels
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="账单状态">
                <el-select
                  v-model="filterList.status"
                  style="width: 320px"
                  filterable
                  placeholder="请选择"
                >
                  <el-option label="未审核" value="0"></el-option>
                  <el-option label="已审核" value="1"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="业务员">
                <el-select
                  v-model="filterList.saleIds"
                  style="width: 320px"
                  filterable
                  placeholder="请选择"
                  multiple
                >
                  <el-option
                    v-for="item in userOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="客户">
                <el-select
                  v-model="filterList.companyId"
                  style="width: 320px"
                  filterable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in companyOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="账单号">
                <base-muti-input
                  ref="baseMutiInput"
                  v-model="filterList.numbers"
                  style="width: 320px"
                ></base-muti-input>
              </el-form-item>
            </el-form>
          </div>
        </BaseFilter>
      </div>
    </div>
    <div class="data">
      <BaseTable
        v-if="height !== 0"
        :height="height"
        :tableData="tableData"
        :pageIndex="pager.pageIndex"
        :pageSize="pager.pageSize"
        :loading="loading"
        @handlePageChange="handlePageChange"
        @handleSelectionChange="handleSelectionChange"
        @sortChange="sortChange"
      >
        <template slot="statusName" slot-scope="row">
          <div v-if="row.scope.status === 0" class="flex_c_c status red">
            {{ row.scope.statusName }}
          </div>
          <div v-if="row.scope.status === 1" class="flex_c_c status green">
            {{ row.scope.statusName }}
          </div>
        </template>
        <template slot="opt" slot-scope="row">
          <div class="table-opt">
            <span
              v-if="!row.scope.status"
              v-permissson="'audit'"
              @click="audit(row.scope)"
            >审核</span
            >
            <span
              v-permissson="'detail'"
              @click="detail('FYCX', row.scope)"
            >详情</span
            >
          </div>
        </template>
        <template #custom-total>
          <div class="custom-total">
            <div style="display: flex;">
              <div class="select">已选 {{ selectionItem.length }} 项</div>
              <div class="total">
                账单金额：<span>{{ totalAmount }} USD</span>
              </div>
            </div>
          </div>
        </template>
      </BaseTable>
    </div>
    <GenerateBillDialog
      :dialogVisible="dialogVisible1"
      @cancel="dialogVisible1 = false"
      @ok="ok"
    ></GenerateBillDialog>
  </div>
</template>

<script>
const BaseTable = () => import('@/components/table/BaseTable')
const BaseFilter = () => import('@/components/filter/BaseFilter')
const BaseMutiInput = () => import('@/components/base/BaseMutiInput.vue')
import GenerateBillDialog from './components/GenerateBillDialog'
import { getRouteByCode } from '@/utils/common'
import { getPages, reviewInvoice } from '@/api/fee'
import { getSaleList } from '@/api/system'
import { derive } from '@/utils/common'
import { mapGetters } from 'vuex'
import { myMixins } from '@/mixins'
export default {
  name: 'BillWnquiry',
  components: {
    BaseTable,
    BaseFilter,
    BaseMutiInput,
    GenerateBillDialog
  },
  mixins: [myMixins],
  data() {
    return {
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }]
        },
      tableData: {
        fileds: [
          {
            filed: 'companyId',
            label: '客户',
            width: '230',
            formatter: (row) => {
              const item = this.companyDrapdown.find(
                (a) => a.value === row.companyId
              )
              if (item) {
                return item.label
              } else {
                return ''
              }
            }
          },
          {
            filed: 'invoiceNumber',
            label: '账单号码',
            width: '140'
          },
          {
            filed: 'invoiceDate',
            label: '账单日期',
            width: '220',
            formatter: (row) => {
              if (row.startDate && row.endDate) {
                return row.startDate.slice(0, row.startDate.length - 9) + ' - ' + row.endDate.slice(0, row.endDate.length - 9)
              } else {
                return ''
              }
            }
          },
          // {
          //   filed: 'startDate',
          //   label: '起始日期',
          //   width: '150',
          //   formatter: (row) =>
          //     row.startDate
          //       ? row.startDate.slice(0, row.startDate.length - 3)
          //       : ''
          // },
          // {
          //   filed: 'endDate',
          //   label: '截止日期',
          //   width: '150',
          //   formatter: (row) =>
          //     row.endDate
          //       ? row.endDate.slice(0, row.endDate.length - 3)
          //       : ''
          // },
          {
            filed: 'invoiceAmount',
            label: '账单金额',
            width: '140',
            formatter: (row) =>
              row.invoiceAmount ? row.invoiceAmount + ' USD' : '0 USD'
          },
          {
            filed: 'statusName',
            label: '状态',
            width: '120',
            isCustom: true
          },
          {
            filed: 'beforeBalance',
            label: '前期余额',
            width: '160',
            formatter: (row) =>
              row.beforeBalance ? row.beforeBalance + ' USD' : '0 USD'
          },
          {
            filed: 'clearencePickAmount',
            label: '本期清提',
            width: '150',
            formatter: (row) =>
              row.clearencePickAmount ? row.clearencePickAmount + ' USD' : '0 USD'
          },
          {
            filed: 'deliveryAmount',
            label: '本期派送',
            width: '150',
            formatter: (row) =>
              row.deliveryAmount ? row.deliveryAmount + ' USD' : '0 USD'
          },
          {
            filed: 'currentBalance',
            label: '当前余额',
            width: '140',
            formatter: (row) =>
              row.currentBalance ? row.currentBalance + ' USD' : '0 USD'
          },
          {
            filed: 'createdName',
            label: '创建人',
            width: '100'
          },
          {
            filed: 'createdOn',
            label: '创建日期',
            width: '150',
            isSort: true,
            formatter: (row) =>
              row.createdOn
                ? row.createdOn.slice(0, row.createdOn.length - 3)
                : ''
          },
          {
            filed: 'saleName',
            label: '销售员',
            width: '180'
          },
          {
            filed: 'opt',
            label: '操作',
            width: '130',
            fixed: 'right',
            isCustom: true
          }
        ],
        columns: [],
        total: 0,
        showIndex: true,
        mutiCheck: true,
        mutiFixed: 'left'
      },
      loading: false,
      pager: {
        pageIndex: 1,
        pageSize: 20
      },
      height: 0,
      filterList: {
        numbers: '',
        companyId: '',
        invoiceDate: '',
        status: '',
        saleIds: ''
      },
      isActive: false,
      dialogVisible1: false,
      rowData: {},
      sortObj: {},
      selectionItem: [],
      userOptions: []
    }
  },
  computed: {
    ...mapGetters(['companyDrapdown']),
    companyOptions() {
      return this.companyDrapdown
    },
    totalAmount() {
     let sum = 0
     this.selectionItem.forEach(a => {
      sum += +a.invoiceAmount
     })
     return sum.toFixed(2)
    }
  },
  created() {
    if (!this.companyDrapdown.length) {
      this.$store.dispatch('getCompanyDrapdown')
    }
    this.getUserList()
  },
  mounted() {
    this.height = document.querySelector('.data').offsetHeight - 93
    this.getList(this.pager)
  },
  methods: {
    getUserList() {
      getSaleList().then((res) => {
        if (res.success) {
          const arr = []
          res.data.map((item) => {
            arr.push({
              value: item.id,
              label: item.nameCH
            })
          })
          this.userOptions = arr
        } else {
          this.$message.error(res.errorMessage)
        }
      })
    },
    async getList(params) {
      this.loading = true
      try {
        params = {
          ...params,
          queryParam: {
            ...this.sortObj,
            ...this.formatterFilter()
          }
        }
        const res = await getPages(params)
        if (res.success) {
          this.loading = false
          res.data.list && (this.tableData.columns = res.data.list)
          this.tableData.total = res.data.total
        } else {
          this.loading = false
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    formatterFilter() {
      return {
        numbers:
          this.filterList.numbers.length > 0
            ? this.filterList.numbers
            : undefined,
            saleIds:
          this.filterList.saleIds.length > 0
            ? this.filterList.saleIds
            : undefined,
        companyId:
          this.filterList.companyId !== ''
            ? this.filterList.companyId
            : undefined,
        status:
          this.filterList.status !== '' ? this.filterList.status : undefined,
        startDate:
          this.filterList.date !== ''
            ? this.filterList.invoiceDate[0]
            : undefined,
        endDate:
          this.filterList.date !== ''
            ? this.filterList.invoiceDate[1]
            : undefined
      }
    },
    initList() {
      const param = {
        pageIndex: 1,
        pageSize: 20
      }
      this.pager = param
      this.reset()
      this.getList(param)
    },
    sendFilter() {
      if (
        this.filterList.numbers.length !== 0 ||
        this.filterList.saleIds.length !== 0 ||
        this.filterList.invoiceDate !== '' ||
        this.filterList.status !== '' ||
        this.filterList.companyId !== ''
      ) {
        this.isActive = true
      } else {
        this.isActive = false
      }
      const params = {
        pageIndex: 1,
        pageSize: 20
      }
      this.pager = params
      this.getList(params)
    },
    reset() {
      this.filterList = {
        numbers: '',
        companyId: '',
        invoiceDate: '',
        status: '',
        saleIds: ''
      }
      this.$refs.baseMutiInput.reset()
      this.isActive = false
    },
    handlePageChange(page) {
      this.pager = page
      this.getList(page)
    },
    sortChange(params) {
      this.sortObj = {
        sortField: params.prop,
        sortBy: params.order
      }
      this.getList(this.pager)
    },
    handleSelectionChange(items) {
      this.selectionItem = items
    },
    ok() {
      this.dialogVisible1 = false
      this.getList(this.pager)
    },
    // 审核
    async audit(row) {
      try {
        const result = await reviewInvoice({ invoiceId: row.id })
        if (result.success) {
          this.getList(this.pager)
          this.$message.success(result.errorMessage)
        } else {
          this.$message.error(result.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    derive() {
      if (!this.selectionItem.length) {
        this.$message.warning('请选择导出行')
        return
      }
      if (this.selectionItem.length > 1) {
        this.$message.warning('导出只能选择一条')
        return
      }
      const params = {
        invoiceId: this.selectionItem[0].id
      }
      derive('CustomerInvoice/downLoadInvoiceFile', 'get', params)
    },
    detail(menuCode, row) {
      const path = getRouteByCode(menuCode)
      // console.log(path, menuCode, btnCode)
      const params = { menuCode, queryJson: JSON.stringify({
          date: [row.startDate, row.endDate],
          companyId: row.companyId
      }) }
      this.$store.commit('SET_CONNNET', params)
      if (path !== this.$route.fullPath) {
        this.$router.push(path)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.view-contanier {
  width: 100%;
  padding: 0 10px;
  height: 100%;
  background: #fff;
  box-sizing: border-box;
  overflow: auto;
  .header {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .opt {
    display: flex;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    & div {
      height: 30px;
      box-sizing: border-box;
      margin-right: 5px;
      border-radius: 4px;
      cursor: pointer;
    }
    .derive {
      width: 80px;
      border: 1px solid #2395f3;
      color: #2395f3;
    }
    .generate {
      width: 100px;
      border: 1px solid #35ba83;
      color: #35ba83;
    }
  }
  .data {
    width: 100%;
    height: calc(100% - 50px);
    & .fTable {
      border-top: 1px solid #ebeef5;
      box-sizing: border-box;
    }
    .custom-total {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 50px;
      background: #f7f7f7;
      border: 1px solid #eeeeee;
      border-top: 0;
      padding-left: 17px;
      padding-right: 40px;
      box-sizing: border-box;
      .select {
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #999999;
      }
      .total {
        margin-left: 16px;
        font-size: 14px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        color: #333333;
        span {
          color: #fe453d;
          font-weight: bold;
        }
      }
    }
    .status {
      width: 60px;
      height: 26px;
      border-radius: 4px 4px 4px 4px;
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      margin: 0 auto;
    }
    .green {
      background: rgba(53, 186, 131, .1);
      color: #35BA83;
    }
    .red {
      background: rgba(255, 116, 68, .1);
      color: #FF7444;
    }
  }
  .table-opt {
    display: flex;
    justify-content: space-around;
    span {
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #2395f3;
      cursor: pointer;
      & + span {
        margin-left: 20px;
      }
    }
  }
}
</style>
